<template>
  <Modal
      v-if="showPayment"
      @close="$emit('close')"
  >
    <div class="pt-3 pb-3">
      <div class="row">
        <div class="col-24">
          <h4>Payment</h4>
        </div>
      </div>
      <div class="row billing">
        <div class="col-24 text-center">
          <h2 class="mb-0"><sup>$</sup>5</h2>
          <span class="text-capitalize">per month</span>
        </div>
      </div>
      <div class="row">
        <div class="col-24">
          <Paypal
              @ready="isLoading = false"
              @success="$emit('success')"
          ></Paypal>
        </div>
      </div>
      <div class="row">
        <div class="col-24 text-center">
          <span class="small text-muted">Choose a payment method to continue.</span>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-24">
          <button type="button" class="btn btn-block btn-bc btn-purple-900"
                  @click="$emit('close')"
          >Cancel</button>
        </div>
      </div>
    </div>
    <Loading :isLoading="isLoading"></Loading>
  </Modal>
</template>

<script>
import Loading from '@/components/common/Loading'
import Modal from '@/components/common/Modal'
import Paypal from '@/views/premium/Paypal'

export default {
  components: {
    Modal,
    Paypal,
    Loading,
  },
  props: {
    showPayment: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isLoading: true,
    }
  },
}
</script>

<style scoped lang="scss">
.billing {
  padding: 30px 0;
  sup {
    top: 13px;
    left: -5px;
    font-size: 0.5em;
    font-weight: 500;
    vertical-align: top;
  }
}

</style>

<template>
  <paypalButtons
      :createSubscription="createSubscription"
      :onApprove="onApprove"
      :onInit="onInit"
  ></paypalButtons>
</template>

<script>
import Vue from 'vue'
import config from '@/config'
import { getApiAuth } from '@/utils/credentials.utils'

const { apiUrl, paypalPlanId } = config

export default {
  components: {
    'paypalButtons': window.paypal.Buttons.driver('vue', Vue),
  },
  methods: {
    createSubscription(data, actions) {
      const res = actions.subscription.create({
        plan_id: paypalPlanId
      })
      console.log('res = ', res)
      res.then(async (subscriptionId) => {
        console.log('subscriptionId = ', subscriptionId)
        await this.$http.post(apiUrl + '/account/premium', {
          subscriptionId,
          paymentSystem: 'paypal',
        }, {
          auth: getApiAuth()
        })
      })

      return res
    },
    async onApprove(data) {
      this.$emit('success', data)
    },
    onInit() {
      this.$emit('ready')
    }
  }
}
</script>

<style scoped lang="scss">
</style>

<template>
  <div class="wave-bg">
    <AuthorizedHeader></AuthorizedHeader>
    <Layout>
      <vue-scroll>
        <div class="container pt-4">

          <!--title-->
          <div class="title text-center">
            <h2 class="title-separator-center">
              Bearcat Premium
            </h2>
            <p class="title-description text-muted">
              This subscription helps us maintain the service and release new features! Please consider supporting us if you're enjoying Bearcat, and in return we'll give you some nice perks.
            </p>
          </div>

          <!--price-->
          <div class="price text-center mt-4">
            <h2 class="mb-0"><sup>$</sup>5</h2>
            <span class="text-capitalize">per month</span>
          </div>

          <!--start-->
          <div class="start text-center mt-4">
            <button type="button" class="btn btn-block btn-purple-900"
                    @click="showPayment = true"
            >
              Let's Start
            </button>
          </div>

          <!--features-->
          <div class="features mt-4">
            <div class="row justify-content-center">

              <div class="col-lg-6 col-md-12 feature text-center">
                <div class="feature-description">
                  <i class="zmdi zmdi-lock-outline"></i>
                  <h5>Private Rooms</h5>
                  <span class="text-muted">Hide a room from other users and invite only your friends.</span>
                </div>
              </div>

              <div class="col-lg-6 col-md-12 feature text-center">
                <div class="feature-description">
                  <i class="zmdi zmdi-flash"></i>
                  <h5>Skip the Queue</h5>
                  <span class="text-muted">No more queueing for rooms, we'll give you one instantly!</span>
                </div>
              </div>

              <div class="col-lg-6 col-md-12 feature text-center">
                <div class="feature-description">
                  <i class="zmdi zmdi-hourglass-outline"></i>
                  <h5>No Time Limit</h5>
                  <span class="text-muted">Watch for hours without worrying about the time.</span>
                </div>
              </div>

<!--              <div class="col-lg-6 col-md-12 feature text-center">-->
<!--                <div class="feature-description">-->
<!--                  <i class="zmdi zmdi-globe-alt"></i>-->
<!--                  <h5>VPN</h5>-->
<!--                  <span class="text-muted">Choose a custom location for the virtual browser.</span>-->
<!--                </div>-->
<!--              </div>-->

            </div>
          </div>

          <!--payment-->
          <Payment
              :showPayment="showPayment"
              @close="showProgress=false; showPayment = false"
              @success="showProgress=true; showPayment = false"
          ></Payment>
          <!--progress-->
          <Progress
              :showProgress="showProgress"
              @close="showProgress=false; showPayment = false"
          ></Progress>
        </div>
      </vue-scroll>
    </Layout>
  </div>
</template>

<script>
import AuthorizedHeader from '@/components/header/AuthorizedHeader'
import Layout from '@/components/sidebar/Layout'
import Payment from '@/views/premium/Payment'
import Progress from '@/views/premium/Progress'

export default {
  components: {
    AuthorizedHeader,
    Layout,
    Payment,
    Progress,
  },
  data() {
    return {
      showPayment: false,
      showProgress: false,
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.title {
  .title-description {
    max-width: 500px;
    margin: auto;
  }
}

.price sup {
  top: 13px;
  left: -5px;
  font-size: 0.5em;
  font-weight: 500;
  vertical-align: top;
}

.start {
  max-width: 300px;
  margin: auto;
  .btn {
    font-weight: 500;
    border-radius: 44px;
    padding: 15px 44px;
    &:before {
      border-radius: 44px;
    }
  }
}

.features {
  .feature {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .feature-description {
    padding: 15px;
    background-color: $bc-gray-800;
    border-radius: 20px;
    i {
      font-size: 40px;
      margin-bottom: 15px;
    }
  }
}
</style>

<template>
  <Modal
      v-if="showProgress"
      @close="$emit('close')"
      :isClosable="false"
  >
    <div class="pt-3 pb-3">
      <div class="row"
           v-if="subscriptionStatus === 'ACTIVE'"
      >
        <div class="col-24">
          <span>Your account was successfully upgraded to Premium! Thank you for your trust 💛</span>
        </div>
      </div>
      <div class="row"
           v-else
      >
        <div class="col-24 text-center">
          <vue-ellipse-progress
              :loading="true"
              :legend="false"
              :size="60"
              color="#dbdbdb"
              empty-color="#1f1f23"
          />
          <div class="">Payment processing...</div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-24">
          <router-link class="btn btn-block btn-bc btn-purple-900"
                       :to="`/room-creation`"
          >
            Ok
          </router-link>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import config from '@/config'
import Modal from '@/components/common/Modal'
import { getApiAuth } from '@/utils/credentials.utils'

const { apiUrl } = config

export default {
  components: {
    Modal,
  },
  props: {
    showProgress: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    showProgress(showProgress) {
      if (showProgress) {
        this.$nextTick(async () => {
          const response = await this.$http.post(apiUrl + '/account/premium/subscription/sync', {}, {
            auth: getApiAuth()
          })
          const { expiredAt, subscriptionId, subscriptionStatus, userId, id, paymentSystem, expiredInSeconds } = response.data.result || {}
          this.$store.commit('account/setAccount', {
            premium: { expiredAt, subscriptionId, subscriptionStatus, userId, id, paymentSystem, expiredInSeconds }
          })
          this.subscriptionStatus = subscriptionStatus
          if (subscriptionStatus === 'ACTIVE') {
            try {
              this.$gtag.event('2: Оформить подписку', {
                event_category: 'premium',
                event_action: 'purchase',
                value: 5,
              })
            } catch (e) {
              console.error(e)
            }
          }
        })
      }
    }
  },
  data() {
    return {
      subscriptionStatus: null
    }
  },
}
</script>

<style scoped lang="scss">
</style>
